import {InjectionToken} from '@angular/core'
import {TStageName} from '../common/types'

/**
 * Bla bla taking the long route to inject
 * the environment to get hold of the "test" | 'prod'
 * setting
 */
export interface IEnvironment {
  siteList: TStageName
}

export const ENVIRONMENT =
  new InjectionToken<IEnvironment>('environment')

export const LOCAL_STORAGE =
  new InjectionToken<Storage>('localStorage')
