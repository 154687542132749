import {Inject, Injectable, signal} from '@angular/core'
import {Router} from '@angular/router'
import {HelperService, SingleSignOnService, SparbankenUser} from '@sparbanken-syd/sparbanken-syd-bankid'
import {BehaviorSubject, EMPTY, Observable, of, switchMap} from 'rxjs'
import {catchError} from 'rxjs/operators'
import {environment} from '../../environments/environment'
import {HOME_ROUTE_PATH, LOGIN_ROUTE_PATH} from '../application/types'
import {ENVIRONMENT, IEnvironment} from '../application/app'

/**
 * Other parts of the application wants to know this data.
 * Note that we DO NOT send the access token here as it
 * is only needed by the auth interceptor, and it reads it
 * from the Observable.
 */
export interface ILogInState {
  /**
   * Simple yes no for admin if we need it.
   */
  admin: boolean

  /**
   * User's name used in the header component
   */
  name: string

  /**
   * User's roles to be used filtering sites
   */
  roles: string[]
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  /**
   * The access token, primarily needed for the auth interceptor,
   * Note that signal does not work due to that user self is called
   * before the value is propagated.
   */
  public accessToken$ = new BehaviorSubject<string | null>(null)

  /**
   * Signal to let know if the user has any of the roles required, not necessarily admin,
   * it could be any defined in {@link USER_ROLES}
   */
  public isValidUser$ = signal<boolean>(false)

  /**
   * Listen to this when you want to know if the login state has changed.
   */
  public logInState$: Observable<ILogInState | null>

  /**
   * Use this to set the name, we have it here
   * to avoid circular dependencies to the site service.
   */
  public siteName$ = signal<string>('Tjänsteportalen')

  /**
   * Private so that we prevent others from publishing
   */
  private pLogInState$ = new BehaviorSubject<ILogInState | null>(null)


  constructor(
    private ssoService: SingleSignOnService,
    private helperService: HelperService,
    private router: Router,
    @Inject(ENVIRONMENT) environment: IEnvironment
  ) {
    this.logInState$ = this.pLogInState$.asObservable()
    if (environment.siteList !== 'prod') {
      this.siteName$.set('Tjänsteportalen testmiljö')
    }
  }

  /**
   * This is called from the app module bootstrapper only. So
   * it will happen once and once only.
   */
  public bootstrap(): Observable<boolean> {
    return this.sso()
      .pipe(
        switchMap((value: string | null) => {
          return this.setToken(value)
        })
      )
  }

  /**
   * Call the SSO service, if we get something we return
   * that. Otherwise, nothing. Must be anonymous since we
   * call it from merge/concat
   */
  public sso(): Observable<string> {
    return this.ssoService
      .getToken(environment.authServiceUrl, environment.domain)
      .pipe(
        catchError(() => {
          // We MUST _reset_ if the SSO service says we are logged out!
          // But we do not call the logout again, that is redundant.
          this.reset()
          return EMPTY
        })
      )
  }

  /**
   * Called whenever we have token
   */
  public setToken(token: string | null): Observable<boolean> {
    const payLoad = HelperService.GetTokenPayload(token)
    if (payLoad) {
      this.accessToken$.next(token)
      this.isValidUser$.set(payLoad.roles.length > 0)
      this.setUserData()

      // In this case we go to home page.
      return of(false)
    }
    /**
     * If we are called with invalid tokens we reset all log in data
     * we do not explicitly LOG OUT!
     */
    this.reset()
    return of(false)
  }

  public login(token: string): void {
    this.setToken(token)
    this.router.navigate([HOME_ROUTE_PATH]).then()

  }

  public logout(): void {
    // Blindly just log out from SSO, ignore any errors
    this.ssoService.deleteToken(environment.authServiceUrl).subscribe()
    // Reset all values and navigates to login
    this.reset()
  }

  /**
   * Reset all admin values, and go to login. Do
   * not explicitly log out.
   */
  public reset(): void {
    // This can potentially be a long list of resets...
    this.accessToken$.next(null)
    this.isValidUser$.set(false)
    this.pLogInState$.next(null)
    // Go to log-in
    this.router.navigate([LOGIN_ROUTE_PATH]).then()
  }

  /**
   * In many cases we also want to fetch the user info we do this
   * totally asynchronous and happily accept that the access token
   * is set properly etc.
   *
   * In Legacy applications we have used this to trigger a reset, which is
   * bad.
   */
  private setUserData(): void {
    this.helperService.getCurrentUser(`${environment.authServiceUrl}`)
      .subscribe({
        next: (user: SparbankenUser) => {
          // Emit login data to subscribers
          this.pLogInState$.next({
            admin: this.isValidUser$(),
            name: user.name,
            roles: user.roles
          })
        }
      })
  }
}
